import React, { useEffect } from "react";
import { navigate } from "gatsby";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  registerHero,
  registerTrusted,
  registerWhiteGlove,
  registerLargeFeatures,
  registerBusinessTypesCar,
  registerVideo,
  registerSolutionsSection,
  registarSolutionsSlides,
  // registerTestimonials,
  registerCTA,
  // registerFaqs,
} from "../../../data/product-pages/register-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import {
  activmealsSlides,
  heroSuccess,
} from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "terminal-pp-white-glove.png";

const Register = () => {
  useEffect(() => {
    navigate("/retail/");
  }, []);
  return (
    <Layout>
      <SEO
        title="SpotOn Register | Point-of-Sale"
        description="Run and grow your business more efficiently than ever before with a compact, powerful point-of-sale that helps you stay connected with your customers"
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={registerHero} circleBg={false} />
      <TrustedNumbers numbersArray={registerTrusted} />
      <WhiteGlove
        sectionData={registerWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={registerLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={registerBusinessTypesCar} />
      <VideoSection sectionData={registerVideo} />
      <SuccessStories sectionData={heroSuccess} slides={activmealsSlides} />
      <PartnerLogos />
      <ProductLines
        sectionData={registerSolutionsSection}
        productSlides={registarSolutionsSlides}
      />
      <LargeCta sectionData={registerCTA} />
      {/* <Faqs sectionData={registerFaqs} /> */}
    </Layout>
  );
};

export default Register;
