/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const registerHero = {
  title: "The point-of-sale your business deserves",
  subtext:
    "Boost efficiency and connect with customers like never before to accelerate revenue growth with SpotOn Register.",
  heroImg: "register-hero.png",
  imageClassName: "fixed-width fixed-height",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/register/demo",
  },
  fatTitle: "Register",
};

export const registerTrusted = [
  {
    title: "300 million",
    subtext: "Marketing campaigns",
  },
  {
    title: "5+ million",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+ million",
    subtext: "Consumers",
  },
];

export const registerWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Grow your business with a team you can trust",
  content:
    "Programming a new point-of-sale and training your staff how to use it can be time-consuming and frustrating. That’s why our team is with you every step of the way, from building your product catalog to adding your individual employee profiles and giving you personalized training. And we’ll continue to be there to help you get the most from SpotOn with 24/7 lifetime support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/register/demo",
  },
};

export const registerLargeFeatures = {
  sectionTitle: "Cutting-edge technology to run and grow your business",
  featureBlocks: [
    {
      blockTitle: "Streamline the checkout process",
      blockImg: "register-a.png",
      blockSubTitle:
        "SpotOn Register combines powerful hardware with intuitive software to help you manage nearly every aspect of your business at the point of sale.",
      blockList: [
        "All-in-one design, including cash register and card reader",
        "Accept cards, cash, mobile pay, and gift cards",
        "Oversee multiple location",
        "Full product catalog and inventory management",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/register/demo",
      },
    },
    {
      blockTitle: "Build your customer list. Drive revenue growth",
      blockImg: "register-b.png",
      blockSubTitle:
        "SpotOn Register is integrated with SpotOn Marketing and SpotOn Loyalty, making it easy to collect customer contact information during checkout and then drive repeat visits with marketing emails, deals, and loyalty rewards.",
      blockList: [
        "Collect customer email addresses",
        "Integrated loyalty check-ins",
        "Integrated deal and reward redemptions",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/register/demo",
      },
    },
    {
      blockTitle: "Work smarter with powerful back-office tools",
      blockImg: "register-c.png",
      blockSubTitle:
        "SpotOn Register gives you all the tools you need to streamline processes and boost efficiency.",
      blockList: [
        "Create users and groups with custom employee permissions",
        "Inventory tracking, automated reorder notifications, and exportable inventory lists",
        "Cloud based reporting to view data directly on your Register or online from anywhere",
        "Track employee hours with clock-in and clock-out, and easily export timesheets",
        "Reinforce your brand with customizable receipt layouts",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/register/demo",
      },
    },
    {
      blockTitle: "Go sleeker with SpotOn Register Lite",
      blockImg: "register-d.png",
      blockSubTitle:
        "Save money and get all the power of our full-sized point-of-sale in a sleek, compact package.",
      blockList: [
        "Built-in EMV, Apple Pay, and Android Pay\n" +
          "-enabled card reader with swiper for gift cards",
        "8” merchant-facing touchscreen for \n" + "lightning-fast checkouts",
        "4.3” customer-facing display for order \n" +
          "confirmation, tip options, and signature capture",
        "Full product catalog and inventory management",
        "Built-in receipt printer with custom receipt \n" +
          "options, plus external cash register",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/register/demo",
      },
    },
    {
      blockTitle: "Market, manage, invoice, and report",
      blockImg: "register-e.png",
      blockSubTitle:
        "SpotOn Register includes powerful tools to help you grow without constraint. Send marketing emails and deals, manage your online reviews, send invoices with our virtual terminal, view sales reports, and even launch a digital loyalty program to drive repeat visits.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/register/demo",
      },
    },
  ],
};

export const registerBusinessTypesCar = {
  title: "Perfect for businesses requiring a no-hassle point-of-sale",
  subtext:
    "SpotOn Register is compact and easy to use, perfect for businesses requiring a product catalog and back-office tools",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const registerVideo = {
  title: "See SpotOn Register in action",
  videoBtn: "Watch video",
  posterImg: "register-poster.png",
  videoUrlId: "P_OnlVIIHPw",
};

export const registerSolutionsSection = {
  title: "Just one of the many tools in your SpotOn toolbox",
  subText: `SpotOn’s Register is designed to work alongside SpotOn’s other products so you can not only accept payments easily, but also stay connected with your customers and grow revenue.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/products/register/demo",
  },
};

export const registerTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const registerIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and register processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/products/register/demo",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept registers and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/products/register/demo",
      },
    },
  ],
};

export const registerCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/register/demo",
  },
};

export const registerFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/faq",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const registarSolutionsSlides = [
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Websites",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
];
